import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import Pagination from '../components/pagination'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from '../lib/helpers'
import Footer from '../components/footer';
export const query = graphql`
  query ProjectsPageQuery($skip: Int = 0, $pageSize: Int = 9) {
    projects: allSanitySampleProject(
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
      limit: $pageSize
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          mainImage {
            asset {
              fluid(maxHeight: 450) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          startedAt
          endedAt
        }
      }
    }
  }
`
export default function ProjectsPage ({data, pageContext, errors}) {

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <SEO title='Projects' />
      <Container>
        <div className="text-center lg:w-3/4 xl:w-2/3 mx-auto my-16">
          <h1 className="font-bebas-neue uppercase text-5xl sm:text-7xl font-black flex flex-col leading-none dark:text-white text-gray-800">
            Projects
          </h1>
        </div>
      </Container>
      <Container>
        {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />}
        <Pagination
          pageSize={process.env.GATSBY_PAGE_SIZE}
          totalCount={data.projects.totalCount}
          currentPage={pageContext.currentPage || 1}
          skip={pageContext.skip}
          base="/projects"
        />
      </Container>
      <Footer/>
    </Layout>
  )
}
