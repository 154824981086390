import {Link} from 'gatsby'
import React from 'react'
import BlockText from './block-text'
import {format} from 'date-fns'
import Img from 'gatsby-image';


function ProjectPreview (props) {
  return (
    <div className="bg-white border cursor-pointer h-100 h-full m-auto max-w-md overflow-hidden rounded-lg shadow-lg w-full">
      <Link
        className="w-full block h-full"
        to={`/project/${props.slug.current}`}
      >
          {props.mainImage && props.mainImage.asset && (
            <Img fluid={props.mainImage.asset.fluid} objectFit="cover" className="w-full" alt={props.mainImage.alt}/>
          )}
        <div className="bg-white dark:bg-gray-800 w-full p-4">
          <p className='text-gray-800 dark:text-white text-xl font-medium'>{props.title}</p>
          <div className="flex items-center mt-1">
            <div className="text-gray-500 dark:text-gray-300 text-md">{format(props.startedAt, 'MMMM, YYYY')} - {format(props.endedAt, 'MMMM, YYYY')}</div>
          </div>
          {props._rawExcerpt && (
            <div className="text-gray-500 dark:text-gray-300 font-light text-md mt-4">
              <BlockText blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ProjectPreview
