import React from 'react'
import {Link} from 'gatsby'

function Pagination ({ pageSize, totalCount, currentPage, skip, base }) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasPrevPage = prevPage >= 1;
  const hasNextPage = nextPage <= totalPages;
  if(totalPages > 1) {
    return (
      <div className="pagination flex flex-row flex-nowrap justify-between md:justify-center items-center mt-20" aria-label="Pagination">
          <Link
            disabled={!hasPrevPage}
            to={`${base}/${hasPrevPage ? (prevPage == 1 ? '' : prevPage ) : ''}`}
            className={(!hasPrevPage ? 'current-page ' : '')+"flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"}
            title="Previous Page"
          >
              <span className="sr-only">Previous Page</span>
              <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
                  <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
              </svg>
          </Link>

          {Array.from({length: totalPages}).map((_,i) => (
            <Link
              to={`${base}/${i > 0 ? i + 1 : ''}`}
              className={(currentPage === i + 1 ? 'current-page ' : '')+"hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"}
              key={'page-'+i+1}
            >
              {i + 1}
            </Link>
          ))}

          <Link
            disabled={!hasNextPage}
            to={`${base}/${hasNextPage ? nextPage : currentPage}`}
            className={(!hasNextPage ? 'current-page ' : '')+"flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"}
            title="Next Page"
          >
              <span className="sr-only">Next Page</span>
              <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
                  <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
              </svg>
          </Link>
      </div>
    )
  }
  return (<></>)
}
export default Pagination
